<template>
  <div v-if="commonCodeByOrder && gameCount" class="mainBg pt64">
    <section>
      <div class="banner1">
         <!--ul>
            <li>
               <span>LIVE DEALERS SHOWER YOU WITH</span>
               <h4>WELCOME BONUS<i class="gn">100%</i></h4>
            </li>
            <li class="btn">
               <a class="btn1" @click="onClickSignup">즉시가입</a>
            </li>
         </ul-->
      </div>
    </section>
    <section id="maincontent">
      <transition name="fade">
        <ui-swiper />
      </transition>
    </section>
    <section class="mt50 mb120">
      <ul id="mainGameMenu">
        <li data-tab="tab-2" v-if="gameCount['slot']" @click="currentTab = 'tab-3'" :class="{'current': currentTab == 'tab-3'}"><!-- @click="scrollToSection('slotSec')" -->
          <div class="otherWrap" @click="currentTab = 'tab-3'">
            <div>슬롯게임</div>
          </div>
          <div class="imgBg">
            <img class="imgWrap" src="../assets/img/main/slotgame.png">
          </div>
        </li>
        <li data-tab="tab-1" v-if="gameCount['casino']" @click="currentTab = 'tab-1'" :class="{'current': currentTab == 'tab-1'}"><!-- @click="scrollToSection('livecasinoSec')" -->
          <div class="otherWrap" @click="currentTab = 'tab-1'">
            <div>라이브카지노</div>
          </div>
          <div class="imgBg">
            <img class="imgWrap" src="../assets/img/main/livecasino.png">
          </div>
        </li>
        <li data-tab="tab-1" v-if="gameCount['hc-casino']" @click="currentTab = 'tab-2'" :class="{'current': currentTab == 'tab-2'}"><!-- @click="scrollToSection('hotelcasinoSec')" -->
          <div class="otherWrap" @click="currentTab = 'tab-2'">
            <div>호텔카지노</div>
          </div>
          <div class="imgBg">
            <img class="imgWrap" src="../assets/img/main/hotelcasino.png">
          </div>
        </li>
      </ul>

       <section id="tab-1" :key="'tab-1'" v-if="currentTab == 'tab-1'"><!-- id="livecasinoSec" ref="livecasinoSec" -->
         <!--h2 class="gameTitle">
           <p class="w1260 mg0auto"><img src="../assets/img/main/livecasino.png"><span>LIVE CASINO</span></p>
         </h2-->
         <ul class="w1260 betGames betGamesa">
           <template v-for="item in commonCodeByOrder['casino']" v-bind:key="item.vendorKey">
             <li
                 v-if="item.groupCodeName.indexOf('HC-') == -1"
                 @click="onCasinoSelectGame(item.groupCode, item.code)"
             >
               <div class="front" :style="getBackgroundImage(item)">
                  <em class="glogo" :style="getBackgroundLogoImage(item)"></em>

                  <p class="name">
                    {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                    <em :class="item.groupCode">{{ $t('front.gnb.livecasino') }}</em>
                  </p>
               </div>
               <div class="mask">
                   <!--div class="cardBgWrap"></div-->
                   <div>
                     <a>
                         {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}} ▸
                     </a>
                     <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
                   </div>
               </div>
               <!--span class="rblmark">{{ $t('front.main.new') }}</span-->
             </li>
           </template>
         </ul>
       </section>
       <section :key="'tab-2'" v-if="currentTab == 'tab-2'"><!-- id="hotelcasinoSec" ref="hotelcasinoSec" -->
         <!--h2 class="gameTitle">
           <p class="w1260 mg0auto"><img src="../assets/img/main/hotelcasino.png"><span>HOTEL CASINO</span></p>
         </h2-->
         <ul class="w1260 betGames betGamesc">
           <template v-for="item in commonCodeByOrder['casino']" v-bind:key="item.vendorKey">
             <li
                 v-if="item.groupCodeName.indexOf('HC-') > -1"
                 @click="onCasinoSelectGame(item.groupCode, item.code)"
             >
               <div class="front" :style="getBackgroundImage(item)">
                  <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
                  <p class="name">
                    {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                    <em :class="item.groupCode">{{ $t('front.gnb.hotelcasino') }}</em>
                  </p>
               </div>
               <div class="mask">
                 <!--div class="cardBgWrap"></div-->
                 <div>
                    <a>{{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}} ▸</a>
                    <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
                 </div>
               </div>
               <!--span class="rblmark">{{ $t('front.main.new') }}</span-->
             </li>
           </template>
         </ul>
       </section>
       <section :key="'tab-3'" v-if="currentTab == 'tab-3'"><!-- id="slotSec" ref="slotSec" -->
         <!--h2 class="gameTitle">
           <p class="w1260 mg0auto"><img src="../assets/img/main/slotgame.png"><span>SLOT GAME</span></p>
         </h2-->
         <ul class="w1260 betGames betGamesb">
           <template v-for="item in commonCodeByOrder['slot']" v-bind:key="item.vendorKey">
             <li
                 v-if="item.groupCodeName.indexOf('H-') === -1"
                 @click="slotOpen('isSlot', 'slot', item.codeName, item.code)"
             >
               <div class="front" :style="getBackgroundImage(item)">
                 <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
                  <p class="name">
                    {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                    <em :class="item.groupCode">{{ $t('front.gnb.slotgame') }}</em>
                  </p>
               </div>
               <div class="mask">
                <!--div class="cardBgWrap"></div-->
                  <div>
                    <a>
                        {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}} ▸
                    </a>
                    <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
                  </div>
                </div>
             </li>
           </template>
         </ul>
       </section>
    </section>
    <section>
      <div class="banner2">
         <ul>
            <!--li>
               <span>빠르고 쉽게 시작하세요!</span>
               <h4>신규 가입 <i class="yw">웰컴 입금 보너스</i> <i class="gn">100%</i> 챙기러 가기</h4>
            </li-->
            <li class="btn">
               <a class="btn1" @click="onClickSignup">신규가입</a>
               <a class="btn2" @click="goPageByName('charge')">머니충전</a>
            </li>
         </ul>
      </div>
    </section>
    <section>
      <div class="listwrap">
         <div class="servicecenter">
            <h4 class="name">{{ $t('front.common.notice') }}</h4>
            <ul>
              <template v-for="item in noticeList" :key="item">
                <li @click="onClickNoticeRead(item)">
                  <span>{{item.title}}</span>
                  <span>{{item.regDate}}</span>
                </li>
              </template>
              <template v-for="item in listMaxCount - noticeList.length" :key="item">
                <li>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                </li>
              </template>
            </ul>
         </div>
         <!--div class="inoutwrap">
            <h4 class="name">{{ $t('front.main.livecharge') }}</h4>
            <div>
               <div class="btn">
                  <a @click="onChangeType('in')" :class="{'active': type === 'in'}">{{ $t('front.main.livecharge') }}</a>
                  <a @click="onChangeType('out')" :class="{'active': type === 'out'}">{{ $t('front.main.liveExchange') }}</a>
               </div>
               <div v-if="type == 'in'" class="inout">
                  <ul>
                    <template v-for="item in cashInList" :key="item.memId">
                      <li>
                        <span><img src="@/assets/img/icon_inin.svg" />{{item.memId}}</span>
                        <span>{{thousand(Number(item.cashAmt))}} 원</span>
                        <span>{{item.regDate}}</span>
                      </li>
                    </template>
                    <template v-for="item in listMaxCount - cashInList.length" :key="item">
                      <li>
                        <span>&nbsp;</span>
                        <span>&nbsp;</span>
                        <span>&nbsp;</span>
                      </li>
                    </template>
                  </ul>
               </div>
               <div v-if="type == 'out'" class="inout">
                  <ul>
                    <template v-for="item in cashOutList" :key="item.memId">
                      <li>
                        <span><img src="@/assets/img/icon_outout.svg" />{{item.memId}}</span>
                        <span>{{thousand(Number(item.cashAmt*-1))}} 원</span>
                        <span>{{item.regDate}}</span>
                      </li>
                    </template>
                    <template v-for="item in listMaxCount - cashOutList.length" :key="item">
                      <li>
                        <span>&nbsp;</span>
                        <span>&nbsp;</span>
                        <span>&nbsp;</span>
                      </li>
                    </template>
                  </ul>
               </div>
            </div>
         </div-->
      </div>
    </section>
    <div class="mainPopWrap">
      <template v-for="(item) in popupList" v-bind:key="item.bannerIdx">
        <div class="mainPop" v-if="item.delYn == 'N' && !item.isClose" :data-idx="item.bannerIdx">
          <div class="closeWrap">
            <a class="close" @click="popupCloseByCookie(item)">{{$t('front.main.today')}}</a>
            <a class="close" @click="popupClose(item)">{{$t('front.main.close')}}</a>
          </div>
          <img :src="item.bannerImg" />
        </div>
      </template>
    </div>
  </div>

   <!--div class="loginmodal">
      <div class="mloading-container">
         <div class="mloading"></div>
         <div class="mloading-text">loading</div>
      </div>
   </div-->
</template>

<script>

import '@/styles/common.css'
import { getPopupCookie, setPopupCookie, tokenCheck } from '@/libs/auth-helper'

import { getMain, getMainCashStatus, getMainNotice } from '@/api/main'
import { mapState } from 'vuex'

import store from '@/store'
import UiSwiper from '@/components/ui/UiSwiper.vue'
import { thousand } from '../libs/utils'

export default {
  name: 'Index.vue',
  components: {
    UiSwiper
  },
  created () {
    // this.emitter.emit('Loading', true)
    // this.emitter.emit('Loading', true)
    // setTimeout(() => {
    //
    // }, 5000)
    // setTimeout(() => {
    //   this.emitter.emit('Loading', false)
    // }, 8000)
    this.loadMain()
    this.popupMerge()
    this.loadNotice()
    this.loadCashStatus()
  },
  data () {
    return {
      type: 'in',
      currentTab: 'tab-1',
      popupList: {},
      cashInList: [],
      cashOutList: [],
      noticeList: [],
      listMaxCount: 7,
      selectedMenu: '',
      noticeListMaxCount: 7
    }
  },
  watch: {
    popup () {
      this.popupMerge()
    },
    popupLogin () {
      this.popupMerge()
    },
    async userData () {
      await store.dispatch('storePopup')
      if (this.userData) {
        await store.dispatch('storePopupLogin')
      }
      await this.popupMerge()
    }
  },
  computed: {
    ...mapState([
      'userData',
      'commonCode',
      'commonCodeByOrder',
      'popup',
      'popupLogin',
      'banner',
      'gameCount'
    ])

  },
  methods: {
    scrollToSection (sectionId) {
      const sectionElement = document.getElementById(sectionId)
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' })
      }
    },
    onClickNoticeRead (item) {
      console.log(item)
      this.$router.push({ name: 'noticeRead', params: { boardIdx: item.boardIdx } })
    },
    thousand,
    loadCashStatus () {
      getMainCashStatus({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result.data)
          this.cashInList = result.data.cashInList.slice(0, this.listMaxCount)
          this.cashOutList = result.data.cashOutList.slice(0, this.listMaxCount)
        }
      })
    },
    loadNotice () {
      getMainNotice({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result)
          this.noticeList = result.data.boardList.slice(0, this.noticeListMaxCount)
        }
      })
    },
    getBackgroundImage (item) {
      try {
        const image = require('../assets/img/gamecardNew/game_main_pc_' + item.code + '_off.png')
        const imageOff = require('../assets/img/gamecardNew/game_main_pc_' + item.code + '_off.png')
        if (image && imageOff) {
          if (item.isHover) {
            return { backgroundImage: 'url(' + image + ')' }
          } else {
            return { backgroundImage: 'url(' + imageOff + ')' }
          }
        } else {
          return { backgroundImage: 'url()' }
        }
      } catch (e) {
        return { backgroundImage: 'url()' }
      }
    },
    getBackgroundLogoImage (item) {
      try {
        const image = require('../assets/img/glogo' + item.code + '.png')
        return { backgroundImage: 'url(' + image + ')' }
      } catch (e) {
        return { backgroundImage: 'url()' }
      }
    },
    popupMerge () {
      const popupLogin = this.userData ? this.popupLogin : []
      const pList = this.popup.concat(popupLogin)

      this.popupList = {}

      pList.forEach(item => {
        if (!item.isClose) {
          const key = item.bannerIdx
          const siteId = process.env.VUE_APP_SITE_ID || 'moka'
          const name = siteId + '_popup_' + item.bannerIdx
          const cookie = getPopupCookie(name)
          if (cookie) {
            item.isClose = true
          } else {
            item.isClose = false
          }
          if (!this.popupList[key]) {
            this.popupList[key] = item
          }
        }
      })
    },
    popupClose (item) {
      item.isClose = true
    },
    popupCloseByCookie (item) {
      item.isClose = true
      setPopupCookie(item)
    },
    slotOpen (event, groupCode, codeName, code) {
      this.emitter.emit(event, { groupCode, codeName, code })
      this.emitter.emit('Loading', false)
    },
    loadMain () {
      if (this.userData) {
        getMain({}).then(res => {
          // console.log(res)
        })
      }
    },
    onChangeType (type) {
      this.type = type
    }
  },
  async beforeRouteEnter (to, from, next) {
    await tokenCheck()
    // if (result) {
    next()
    // }
  }
}
</script>
<style scoped>
.mt111 {height: 400px}
.loading {z-index: 10;position: absolute;top: 0;width: 100%;height: 310px;padding-top: 40px;display: flex;align-items: center;justify-content: center;}
.loading img {height: 100%;}
.popmark {position: absolute;top: 11px;left: 11px;width: 46px;height: 20px;font-size: 12px;color: #fff;border-radius: 10px;background-color: #d73027;align-items: center;display: flex;justify-content: center;}
.newmark {position: absolute;top: 11px;left: 11px;width: 46px;height: 20px;font-size: 12px;color: #fff;border-radius: 10px;background-color: #4575b4;align-items: center;display: flex;justify-content: center;}
.rrdmark {position: absolute;top: 12px;right: 19px;width: 58px;height: 24px;font-size: 14px;color: #fff;border-radius: 12px;background-color: #d73027;align-items: center;display: flex;justify-content: center;}
.rblmark {position: absolute;top: 12px;right: 19px;width: 58px;height: 24px;font-size: 14px;color: #fff;border-radius: 12px;background-color: #4575b4;align-items: center;display: flex;justify-content: center;}
.gameKinds li h4 {margin: 52px 0 0 20px;font-size: 16px;font-weight: bold;color: #fff;}
.gameKinds li span {margin: 4px 0 0 20px;font-size: 12px;color: #fff;}
h2.mainnames {width: 1314px;margin: 0 auto;padding: 30px 0 20px;font-size: 30px;font-weight: bold;color: #000;}
.name em.casino {color: #dc4a42;}
.name em.slot {color: #f4d03b;}
.name em.hotel {color: #fc7c30;}
.name em.sport {color: #244694;}
.name em.mini {color: #693290;}
li:hover .name, li:hover .name em {color: #fff;}

ul.tabs {display: flex;justify-content: center;align-items: center;padding: 30px 0;width: 1314px;margin: 0 auto;gap: 5px;}
ul.tabs li {display: flex;align-items: center;justify-content: center;font-size: 14px;color: #fff;cursor: pointer;width: 148px;height: 30px;border: solid 1px #777;background: #111;}
.tab-content{margin: 0 auto;}
.tab-content.current{display: inherit;}
ul.tabs li:hover {border: 1px solid #e5972d;background: #333;}
ul.tabs li.current {color: #000;border: 1px solid #e5972d;background: #e5972d;}

.slotmodalwrap::-webkit-scrollbar {width: 10px;}
.slotmodalwrap::-webkit-scrollbar-thumb {background-color: none;border-radius: 10px;background-clip: padding-box;border: 2px solid transparent;}
.slotmodalwrap::-webkit-scrollbar-track {background-color: none;border-radius: 10px;}
.slotmodal {background: #ededed;border: 1px solid #707070;border-radius: 30px;position: absolute;left: 50%;top: 50%; transform: translate(-50%,-50%);z-index: 1;width: 1314px;}
.slotmodal>h3 {width: 100%;position: relative;text-align: center;font-size: 30px;font-weight: bold;color: #fff;padding: 15px 0;background: #222;border-radius: 30px 30px 0 0;}
.slotmodal>h3::after {content: '';display: block;width: 100%;height: 17px;background-image: linear-gradient(to right, #319e8a, #1c594e);position: absolute;bottom: -25px;}
.slotmodal>h3 .close {position: absolute;right: 15px;top: 9px;height: 40px;}
.slotmodalwrap {max-height: 900px;overflow-y: auto;padding-top: 55px;}
.slotmodalwrap ul {padding: 0 65px 30px;display: flex;gap: 25px;justify-content: center;}
.slotmodalwrap ul li {transition: all ease 1s;cursor: pointer;}
.slotmodalwrap ul li:hover, .slotmodal ul li:focus, .slotmodal ul li:active {transform: scale(1.1);}
.slotmodalwrap ul li img {width: 100%;border-radius: 12px;border: 2px solid #319e8a;height: 200px;}

.mainPopWrap {display: flex;align-items: center;gap: 50px;position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%);z-index: 100;}
.mainPop {position: relative;min-width: 320px;background: #fff;border: 1px solid #777;z-index: 100;}
.mainPop .closeWrap {width: calc(100% - 30px);display: flex;align-items: center;justify-content: end;gap: 15px;position: absolute;bottom: 0;padding: 15px;background: rgba(0,0,0,.7);color: #fff;font-size: 12px;}
.mainPop a.close {color: #fff;}
.mainPop>img {width: 430px;height: 640px;vertical-align: bottom;object-fit: cover;object-position: center;}

</style>
<style>
.swiper-button-next {background-image: url(../assets/img/slideright.png) !important;}
.swiper-button-prev {background-image: url(../assets/img/slideleft.png) !important;}
/*.mainSlide img{width:100%;}
.mainSlide .swiper-slide>a{width:100%;display:block;}
.mainSlide .swiper-slide>a img{display:block;width:100%;height: 400px;object-fit:cover;object-position:center;}
.swiper-button-next, .swiper-button-prev {bottom: 50px;top: auto;}
.swiper-button-next {
  background-image: url(../assets/img/slideright.png) !important;
  background-size: 11px 20px !important;
  background-repeat: no-repeat;
  background-position: center;
  right: 50%;
  bottom: 3px;
  margin-right: -640px;
  z-index: 11;
}
.swiper-button-prev {
  background-image: url(../assets/img/slideleft.png) !important;
  background-size: 11px 20px !important;
  background-repeat: no-repeat;
  background-position: center;
  left: 50%;
  bottom: 3px;
  margin-left: -640px;
  z-index: 11;
}
.swiper-button-next:after, .swiper-button-prev:after {content: '';}
.swiper-button-next:hover, .swiper-button-prev:hover {opacity: 1;}
.swiper-pagination {bottom: 0 !important;gap: 100px;background-color: ;height: 50px;display: flex;justify-content: center;align-items: center;}
.swiper-pagination-bullet {width: 105px; min-height: 19px; background: transparent; opacity: 0.3; color: #fff;}
.swiper-pagination-bullet-active {border-radius: 0;opacity: 1;}*/
</style>
<style scoped src="@/styles/header.css"></style>
<style scoped src="@/styles/footer.css"></style>
<style scoped src="@/styles/main.css"></style>
